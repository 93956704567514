import { Component, OnInit } from '@angular/core';
import { TicketsService } from '../../../../services/tickets.service';

@Component({
  selector: 'app-open-tickets-kpi',
  templateUrl: './open-tickets-kpi.component.html',
  styleUrls: ['./open-tickets-kpi.component.scss']
})
export class OpenTicketsKpiComponent implements OnInit {
  ticketTypes = [
    { 'title': 'Instalación', 'total': 0, 'type': 'installation', 'bg': 'success' },
    { 'title': 'Cambio de filtros', 'total': 0, 'type': 'filter_change', 'bg': 'primary' },
    { 'title': 'Cambio de domicilio', 'total': 0., 'type': 'move', 'bg': 'info' },
    { 'title': 'Llamada de servicio', 'total': 0, 'type': 'service_call', 'bg': 'warning' },
    { 'title': 'Desinstalación', 'total': 0, 'type': 'removal', 'bg': 'danger' }
  ];
  regularTickets = [...this.ticketTypes];
  premiumTickets = [...this.ticketTypes];

  constructor(
    private ticketsService: TicketsService
  ) { }

  ngOnInit(): void {
    this.getOpenTickets();
  }

  private getOpenTickets(): void {
    this.ticketsService.openTickets().subscribe((resp: any) => {
      const response = resp.response;
      this.regularTickets = response['regular'];
      this.premiumTickets = response['premium'];
    });
  }
}
