import { registerLocaleData } from '@angular/common';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PreviousTicketDataComponent } from './previous-ticket-data/previous-ticket-data.component';
import { ClientContractDataComponent } from './client-contract-data/client-contract-data.component';
import { CalendarComponent } from './calendar/calendar.component';
import { TicketsComponent } from '../../admin/service/tickets/tickets.component';
import { ChartsComponent } from './charts/charts.component';
import { CancelChargeButtonsComponent } from './cancel-charge-buttons/cancel-charge-buttons.component';
import localeEsMx from '@angular/common/locales/es-MX';
import { OpenTicketsKpiComponent } from '../../admin/service/tickets/open-tickets-kpi/open-tickets-kpi.component';
// import { FlatpickrModule } from 'angularx-flatpickr';
var sharedModules = [
    PreviousTicketDataComponent,
    ClientContractDataComponent,
    CalendarComponent,
    TicketsComponent,
    ChartsComponent,
    CancelChargeButtonsComponent,
    OpenTicketsKpiComponent
];
registerLocaleData(localeEsMx);
var ɵ0 = adapterFactory;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
