<div *ngIf="!type" class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="col-sm-8">
        <h2 class="m-0">Seguimiento de Tickets</h2>
    </div>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <app-open-tickets-kpi></app-open-tickets-kpi>
</div>

<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>