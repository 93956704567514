<div class="container-fluid text-center">
    <div class="w-100">
        <h5 class="mb-3">Ticket abiertos - Servicios regulares</h5>
        <div class="row justify-content-around align-items-center">
            <div *ngFor="let ticket of regularTickets" class="col-auto">
                <app-kpi-card [data]="ticket"></app-kpi-card>
            </div>
        </div>
    </div>
    <div class="mt-3">
        <h5 class="mb-3">Ticket abiertos - Servicios premium</h5>
        <div class="row justify-content-around align-items-center">
            <div *ngFor="let ticket of premiumTickets" class="col-auto">
                <app-kpi-card [data]="ticket"></app-kpi-card>
            </div>
        </div>
    </div>
</div>