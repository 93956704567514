/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./open-tickets-kpi.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/shared/kpi-card/kpi-card.component.ngfactory";
import * as i3 from "../../../../components/shared/kpi-card/kpi-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./open-tickets-kpi.component";
import * as i6 from "../../../../services/tickets.service";
var styles_OpenTicketsKpiComponent = [i0.styles];
var RenderType_OpenTicketsKpiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpenTicketsKpiComponent, data: {} });
export { RenderType_OpenTicketsKpiComponent as RenderType_OpenTicketsKpiComponent };
function View_OpenTicketsKpiComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-kpi-card", [], null, null, null, i2.View_KpiCardComponent_0, i2.RenderType_KpiCardComponent)), i1.ɵdid(2, 114688, null, 0, i3.KpiCardComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OpenTicketsKpiComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-kpi-card", [], null, null, null, i2.View_KpiCardComponent_0, i2.RenderType_KpiCardComponent)), i1.ɵdid(2, 114688, null, 0, i3.KpiCardComponent, [], { data: [0, "data"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OpenTicketsKpiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container-fluid text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h5", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ticket abiertos - Servicios regulares"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row justify-content-around align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenTicketsKpiComponent_1)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h5", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ticket abiertos - Servicios premium"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "row justify-content-around align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenTicketsKpiComponent_2)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.regularTickets; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.premiumTickets; _ck(_v, 12, 0, currVal_1); }, null); }
export function View_OpenTicketsKpiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-open-tickets-kpi", [], null, null, null, View_OpenTicketsKpiComponent_0, RenderType_OpenTicketsKpiComponent)), i1.ɵdid(1, 114688, null, 0, i5.OpenTicketsKpiComponent, [i6.TicketsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpenTicketsKpiComponentNgFactory = i1.ɵccf("app-open-tickets-kpi", i5.OpenTicketsKpiComponent, View_OpenTicketsKpiComponent_Host_0, {}, {}, []);
export { OpenTicketsKpiComponentNgFactory as OpenTicketsKpiComponentNgFactory };
