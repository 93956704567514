import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { SwalService } from './swal.service';
import { BroadcastService } from './broadcast.service';
import { ModalComponent } from '../components/modal/modal.component';
import { SharedComponent } from '../model/shared-component';
import { AssignComponent } from '../admin/service/tickets/assign/assign.component';
import { AssignationCreateEditComponent } from '../admin/service/tickets/assignation-create-edit/assignation-create-edit.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnrealizedComponent } from '../admin/service/tickets/unrealized/unrealized.component';
import { CreateSupportTicketComponent } from '../admin/clients/create-support-ticket/create-support-ticket.component';
import { CloseComponent } from '../admin/service/tickets/close/close.component';
import { UploadPhotosComponent } from '../components/shared/upload-photos/upload-photos.component';
import { RecordComponent } from '../admin/service/tickets/record/record.component';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  baseUrl = `${environment.apiUrl}`;
  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly swalService: SwalService,
    private readonly http: HttpClient) { }

  create(params?): any {
    return this.http.post(`${this.baseUrl}/tickets`, params);
  }

  update(id_tickets, params): any {
    return this.http.put(`${this.baseUrl}/ticket/${id_tickets}`, params);
  }

  getTickets(params?, filters?): Observable<any> {
    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/tickets/get_tickets`, params);
  }

  getTechnicians(params?, filters?): any {
    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/users/Technician`, params);
  }

  getErrorCodes(params?, filters?): any {
    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.get(`${this.baseUrl}/error-codes/get_error_codes`, params);
  }

  getHistory(id_tickets): any {
    const params = {
      params: { id_tickets }
    };

    const assignations = this.http.get(`${this.baseUrl}/assignations`, params);
    const completions = this.http.get(`${this.baseUrl}/completions`, params);
    const confirmations = this.http.get(`${this.baseUrl}/confirmations`, params);
    const unrealizations = this.http.get(`${this.baseUrl}/unrealizations`, params)

    return forkJoin([assignations, completions, confirmations, unrealizations]);
  }

  getTicketTypes(): any {
    return this.http.get(`${this.baseUrl}/tickets/get_ticket_type`);
  }

  getMapTickets(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/get_map_tickets`, params);
  }

  setStatus(params, id): any {
    return this.http.post(`${this.baseUrl}/tickets/${id}/setStatus`, params);
  }

  getPhotos(ticketId: number): any {
    return this.http.get(`${this.baseUrl}/tickets/${ticketId}/photos`);
  }

  addPhotos(params, ticketId: Number): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/${ticketId}/addPhotos`, params);
  }

  simTicketsNextMonth(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/simTicketsNextMonth`, params);
  }

  getInstallationsImages(id_clients: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/tickets/${id_clients}/getInstallationsImages`);
  }

  openTickets(): Observable<any> {
    return this.http.get(`${this.baseUrl}/tickets/openTickets`);
  }

  /***** SharedModals *****/
  /**
   * editTicketModal
   * Abre un modal para editar la fecha - hora de realizacion de un ticket o la
   * descripción sin necesidad de reasignar el ticket
   * @param data informacion del ticket
   */
  editTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignationCreateEditComponent,
      { ticket: data, status: 'edit' },
      { title: `Edición del ticket #${data.id_tickets}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * assignTicketModal
   * Abre un modal para assignar un ticket a un tecnico
   * @param data 
   */
  assignTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'assign' },
      { title: `Asignación de ticket ${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * reassignTicket Modal
   * Abre un modal para reasignar el ticket a otro tecnico
   * @param data 
   */
  reassingTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'reassing' },
      { title: `Reasignación de ticket #${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * unassignTicketModal
   * Abre un modal para marcar como desaasignado un ticket
   * @param data informacion del ticket
   */
  unassignTicketModal(data): void {
    const swalParams = {
      title: '¿Desea desasignar este ticket?',
      text: '¡Si desasigna este ticket el estatus pasara a abierto!'
    };

    this.swalService.warning(swalParams).then(result => {
      if (result.value) {
        const params = {
          status: 'unassigned'
        };
        this.setStatus(params, data.id_tickets).subscribe((res: any) => {
          if (res.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        });
      }
    });
  }

  /**
   * closeTicketModal
   * Abre un modal para ingresar los datos de cerrado del ticket por parte del staff
   * @param data informacion del ticket
   */
  closeTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(CloseComponent, data, { title: `Cerrar el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
    * unrealizedTicketModal
    * Manda una peticion a la API para marcar un ticket como no realizado.
    * @param data  información del ticket
    */
  unrealizedTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UnrealizedComponent,
      data,
      { title: `Marcar como no realizado el ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
    * uploadPhotosTicketModal
    * Abre un modal para subir imagenes que se asociaran al ticket
    * @param data 
    */
  uploadPhotosTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UploadPhotosComponent,
      data,
      { title: `Subir imagenes al ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * recordModal
   * Abre un modal para visualizar el historial de un ticket
   * @param data informacion del ticket 
   */
  recordModal(data): void {
    const props: SharedComponent = new SharedComponent(RecordComponent, data, { title: `Historial del ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.openXl(props);
  }

  /**
   * createSupportTicketModal
   * Abre un modal para crear un ticket de soporte
   * @param data informacion del ticket
   */
  createSupportTicketModal(data?): void {
    let title = 'Crear ticket de soporte';

    if (data && data.name) {
      title = `${title}&nbsppara el cliente&nbsp${data.name}`;
    }

    const props: SharedComponent = new SharedComponent(CreateSupportTicketComponent, data, { title });
    this.appModal.open(props);
  }
}
