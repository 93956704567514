/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kpi-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./kpi-card.component";
var styles_KpiCardComponent = [i0.styles];
var RenderType_KpiCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KpiCardComponent, data: {} });
export { RenderType_KpiCardComponent as RenderType_KpiCardComponent };
function View_KpiCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "small", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.footerText); _ck(_v, 2, 0, currVal_0); }); }
export function View_KpiCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card-deck mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "card-title d-inline p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "card-text d-inline p-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_KpiCardComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ((_co.data == null) ? null : _co.data.footerText); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "card bg-", _co.data.bg, " text-white"); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.data == null) ? null : _co.data.title); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.data == null) ? null : _co.data.total); _ck(_v, 6, 0, currVal_2); }); }
export function View_KpiCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-kpi-card", [], null, null, null, View_KpiCardComponent_0, RenderType_KpiCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.KpiCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KpiCardComponentNgFactory = i1.ɵccf("app-kpi-card", i3.KpiCardComponent, View_KpiCardComponent_Host_0, { data: "data" }, {}, []);
export { KpiCardComponentNgFactory as KpiCardComponentNgFactory };
