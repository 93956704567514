import { Component, Input, OnInit } from '@angular/core';

export interface cardOptions {
  title: string;
  total: string;
  footerText: string;
  bg: string;
};

@Component({
  selector: 'app-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss']
})
export class KpiCardComponent implements OnInit {
  
  @Input() data: cardOptions;

  ngOnInit(): void {
    //
  }

}
